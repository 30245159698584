import React from "react";
import { Navbar, Mintboxlive, Mintboxpresale, Homebanner, Faq, Footer, Members, Homeroadmap, Landing, Hometeam } from '../../components';
import { Divider, Grid, ImageList } from '@mui/material';
import { ThemeProvider } from "@emotion/react";
import { createTheme } from '@mui/material/styles';
import { Whitelist } from '../../components';
import Intro from '../../assets/Citizen.gif';

const bannertheme = createTheme({
  typography: {
    fontFamily: '"Raleway"',
    h1: {
      fontSize: "5rem",
      fontWeight: "bold",
      color: "black",
      fontFamily: '"val stencil"'
    }
  }
});

export default function Home() {
  return (
    <ThemeProvider theme={bannertheme}>
      <Grid item marginLeft={10} marginRight={10} marginTop={2}>
        <Grid container xs={12} md={12} lg={12} p={2} justifyContent="right">
          <Navbar />
        </Grid >
        <Grid container md={12} lg={12} p={2} marginBottom={5}>
            <Landing />
            <Divider sx={{ backgroundColor: 'rgba(255,255,255,.25)' }} />
          <Grid item xs={12} sm={12} md={12} lg={8}>
            <Homebanner />
          </Grid>
          <Grid container xs={12} sm={12} md={12} lg={4} alignItems="right">
            <ImageList sx={{ width: "100%", height: "100%", borderRadius: "10%" }} cols={1}>
              <img src={Intro} p={2} height="100%" width="100%"></img>
            </ImageList>
          </Grid>
        </Grid>
        <Divider sx={{ backgroundColor: 'rgba(255,255,255,.25)' }} />
        <Grid container xs={12} sm={12} md={12} lg={12} alignItems="center">
          <Whitelist />
        </Grid>
        <Members />
        <Divider sx={{ backgroundColor: 'rgba(255,255,255,.25)' }} />
        <Hometeam />
        <Faq />
        <Grid container md={12} lg={12}>
          <Footer />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}