import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Gallery, Home, Origins, Team, Roadmap, Cryptopians, Kingdomtypes, Dualsbattles} from './pages';

class App extends React.Component {
  render(){
    return (
      <BrowserRouter>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/Origins" element={<Origins />} />
          <Route path="/Gallery" element={<Gallery />} />
          <Route path="/Roadmap" element={<Roadmap />} />
          <Route path="/Cryptopians" element={<Cryptopians />} />
          <Route path="/Team" element={<Home />} />
          <Route path="/Kingdoms" element={<Kingdomtypes />} />
          <Route path="/Dualsbattles" element={<Dualsbattles />} />
        </Routes>
      </div>
    </BrowserRouter>
    )
  }
}

export default App;
