import React from 'react';
import { Button, Divider, Grid, Paper, styled, Typography, Stack } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import nft1 from '../../assets/nfts/1.png'
import nft2 from '../../assets/nfts/2.png'
import nft3 from '../../assets/nfts/3.png'
import { ThemeProvider } from "@emotion/react";
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        h1: {
            fontSize: "6rem",
            fontWeight: "Bold",
            color: 'rgba(176, 0, 106)',
            fontFamily: '"Raleway"',
            alignItems: "center"
        },
        h3: {
            fontSize: "2rem",
            fontWeight: "Bold",
            color: 'white',
            alignItems: "center",
            fontFamily: '"Roboto"'
        },
        subtitle1: {
            fontSize: "1rem",
            fontWeight: 600,
            color: 'white',
            alignItems: "center",
            fontFamily: '"Roboto"'
        },
    },
});

const nfts = [[nft1, "DaLogikPapa", "Design.Code.Blockchain."], [nft2, "ShuQueen", "PM.Testing."], [nft3, "Joyverse", "Metaverse Designer."]]

const Hometeam = () => {
    return (
        <Grid container p={2} id="Home/team">
            <ThemeProvider theme={theme}>
                <Grid container spacing={1} xs={12} sm={12} md={12} lg={12} p={2} marginLeft={1}>
                    <Grid item lg={4}>
                        <Typography variant="h1">Team</Typography>
                        <Typography variant="h3" marginTop={3}>A creative, technical squad inspired by toddlers, fantasies, blockchain technology & community.</Typography>
                        <br />
                    </Grid>
                    <Grid item lg={8} marginTop={5}>
                        <Grid container>
                            {nfts.map((nft) => (
                                <Grid item xs={12} sm={6} md={4} padding={1}>
                                    <Card sx={{ maxWidth: 400, borderRadius:"50%" }}>
                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                                image={nft[0]}
                                            />
                                        </CardActionArea>
                                    </Card >
                                    <br />
                                    <Typography variant="h3" align="center">{nft[1]}</Typography>
                                    <Typography variant="subtitle1" align="center">{nft[2]}</Typography>
                                </Grid>
                            ))}
                        </Grid>
                        <br />
                        <br />
                    </Grid>
                </Grid>
            </ThemeProvider>
        </Grid>
    );
}

export default Hometeam;
