import React from 'react';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Masonry from '@mui/lab/Masonry';
import { Navbar, Footer } from '../../components';
import { ThemeProvider } from "@emotion/react";
import { createTheme } from '@mui/material/styles';
import { Homeroadmapdata } from './Homeroadmapdata';

const theme = createTheme({
    typography: {
        fontFamily: '"Helvetica Neue"',
        h1: {
            fontSize: "6rem",
            fontWeight: "Bold",
            color: 'rgba(176, 0, 106)',
            fontFamily: '"Big Shoulders Stencil Display"',
            alignItems: "center"
        },
        h2: {
            fontSize: "1.5rem",
            fontWeight: 600,
            color: 'rgba(71, 68, 112)',
            alignItems: "center"
        },
        body1: {
            fontSize: ".9rem",
            fontWeight: 400,
            color: "white",
            alignItems: "center",
            fontFamily: '"Roboto"',
        },
        body2: {
            fontSize: "1.5rem",
            fontWeight: 300,
            color: "white",
            alignItems: "center",
            fontFamily: '"Roboto"',
        }
    }
});

export default function FixedColumns() {
    return (
        <ThemeProvider theme={theme}>
            <Grid container p={2}>
                <Grid container lg={12} spacing={1} marginTop={3} marginLeft={2} marginRight={2} marginBottom={3}>
                    <Grid container md={12} lg={4} p={2}>
                        <Grid item>
                            <Typography variant="h1">The Journey</Typography>
                            <Typography variant="body2">Well, we are off on a journey to see this through. Here are some of the goals we hope to hit. Although these are only high level, we hope the goal post is a moving target and evolves as the community grows. We are open to ideas from the community. The community is what will make Kingdoms of cryptopia fun. </Typography>

                        </Grid>
                    </Grid>
                    <Grid container md={12} spacing={1} lg={8} >
                            {Homeroadmapdata.map((data, index) => {
                                return (
                                    <Grid item xs={12} sm={12} md={12} lg={3} className="flip-card">
                                        <Grid item sx={12} lg={12} justifyContent="center" borderRadius={5} className="flip-card-inner">
                                            <Paper elevation={24} sx={{ background: 'rgba(176, 0, 106)', borderRadius: 5 }} className="flip-card-front">
                                                <Typography variant="h2" sx={{ color: 'rgba(255, 255, 255)' }} marginTop={5}>{data.title}</Typography>
                                                <Typography variant="body1" sx={{ color: 'rgba(255, 255, 255)' }} margin={5}>{data.roadmapdesc1} <br /> <br /> {data.roadmapdesc2} <br /><br />{data.roadmapdesc3}</Typography>
                                            </Paper>
                                            <Paper elevation={24} sx={{ background: 'transparent', borderRadius: 5 }} item className="flip-card-back">
                                                <Typography variant="h2" sx={{ color: 'rgba(98, 0, 255)' }} marginTop={5}>{data.Current_status}</Typography>
                                                <Typography variant="body1" sx={{ color: 'rgba(98, 0, 255)' }} margin={5}>{data.backdate}</Typography>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                )
                            })}
                    </Grid>
                </Grid>
            </Grid >
        </ThemeProvider>
    );
}