import React from 'react';

export const Roadmapdata = [
    {
		title: "Phase 1 Begins",
		roadmapdesc1: "Rainbow Kingdom Mint",
		Current_status: "Minting/Sale To Open",
		backdate: "April Tentative"
	},
	{
		title: "Phase 2",
		roadmapdesc2: "Next Kingdom Voting",
		roadmapdesc3: "Next Kingdom Mint",
		Current_status: "Not Launched",
	},
	{
		title: "Phase 3",
		roadmapdesc1: "New Kingdom Mint Open",
		roadmapdesc2: "Next Kingdom Voting",
		Current_status: "Not Launched",
	},
	{
		title: "Phase 4",
		roadmapdesc1: "Paradise City Access",
		roadmapdesc2: "New Kingdom Open",
		Current_status: "Not Launched",
	},
	{
		title: "Phase 5",
		roadmapdesc1: "New Kingdom Open",
		Current_status: "Not Launched",
	},
]