import React from 'react';
import { Button, Divider, Grid, Paper, styled, Typography, Stack } from '@mui/material';
import { ThemeProvider } from "@emotion/react";
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { FaDiscord, FaTwitter } from 'react-icons/fa';

const gototheme = createTheme({
    typography: {
        fontFamily: '"Helvetica Neue"',
        h1: {
            fontSize: "3rem",
            fontWeight: "bold",
            color: "black"
        },
        body1: {
            fontSize: "1.5rem",
            color: "black",
            fontFamily: '"Helvetica Neue"',
            fontWeight: 150,
        }
    },
});

const Gotobanner = () => {
    return (
        <Grid container justifyContent="center">
            <ThemeProvider theme={gototheme}>
                <Paper
                    style={
                        {
                            borderRadius: "1px",
                            background: 'rgba(78, 189, 212)',
                            width: "100%",
                            height: "80%"
                        }}>
                        <Typography variant="h1" align="center" margin={5}>Visit the Gallery</Typography>
                </Paper>
            </ThemeProvider>
        </Grid>
    )
};

export default Gotobanner;
