import React from 'react';
import { Button, Grid, Typography, Stack, Paper } from '@mui/material';
import { ThemeProvider } from "@emotion/react";
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import landing from '../../assets/Landing2.png';
import { FaDiscord, FaTwitter, FaInstagram } from 'react-icons/fa';



const bannertheme = createTheme({
    typography: {
        fontFamily: '"Helvetica Neue"',
        h1: {
            fontSize: "3.5rem",
            color: 'rgba(176, 0, 106)',
            fontFamily: '"Adoria"',
        },
        h2: {
            fontSize: "1rem",
            color: 'rgba(176, 0, 106)',
            fontFamily: '"Adoria"',
        },
        body1: {
            fontSize: "1.1rem",
            color: 'white',
            fontFamily: '"Roboto"',
        },
        body2: {
            fontSize: ".75rem",
            color: 'white',
            fontFamily: '"Adoria"',
        },
    },
    components: {
        // Name of the component
        MuiButton: {
            styleOverrides: {
                text: {
                    color: 'white',
                    fontSize: '1rem',
                    background: 'rgba(176, 0, 106,0.5)',
                    maxWidth: '2.5rem',
                    maxHeight: '2.5rem',
                    minWidth: '2.5rem',
                    minHeight: '2.5rem',
                    borderRadius: '75%'
                },
            },
        },
    }
});

const Landing = () => {
    return (
        <ThemeProvider theme={bannertheme}>
            <Grid container lg={12}>
                <Grid item xs={12} sm={12} md={12} lg={8}>
                        <img src={landing} height={"100%"} width={"100%"}></img>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4} justifyContent="center" align="center">
                    <Typography marginTop={12} variant="h1">Kingdoms of Cryptopia</Typography>
                    <Typography variant="body1">A Fantasy World evolved by the community with NFT Collectibles on the Ethereum BlockChain.</Typography>
                    <Typography variant="body2" marginTop={5}>JOIN THE COMMUNITY</Typography>
                    <Typography variant="body1" marginTop={1}>
                        <Stack spacing={2} direction="row" justifyContent="center" align="center" marginBottom={3}>
                            <Button href="https://discord.gg/awr9zvsj" target="_blank" rel="noopener noreferrer"><FaDiscord /></Button>
                            <Button href="https://twitter.com/KOCAC4" target="_blank" rel="noopener noreferrer"><FaTwitter /></Button>
                            <Button><FaInstagram /></Button>
                        </Stack>
                    </Typography>
                </Grid>
            </Grid>
        </ThemeProvider >
    )
};

export default Landing;
