import React from 'react';
import './flipcard.css';
import { Grid, Paper, Typography } from '@mui/material';
import { ThemeProvider } from "@emotion/react";
import { createTheme } from '@mui/material/styles';
import { Cdata } from './Cdata';
import AutorenewIcon from '@mui/icons-material/Autorenew';

const theme = createTheme({
    typography: {
        h1: {
            fontSize: "3rem",
            fontWeight: "bold",
            color: 'rgba(176, 0, 106)',
            fontFamily: '"Big Shoulders Stencil Display"',
        },
        body1: {
            fontSize: "1.25rem",
            color: 'rgba(255, 255, 255,0.8)',
            fontFamily: '"Roboto"',
            fontWeight: 250,
        },
        body2: {
            fontSize: "1.5rem",
            color: "black",
            fontFamily: '"Helvetica Neue"',
            fontWeight: 150,
        }
    }
});

function FlipCard() {
    return (
        <ThemeProvider theme={theme}>
            <Grid container spacing={1}>
                {Cdata.map((data, index) => {
                    return (
                        <Grid item xs={12} sm={12} md={6} lg={6} className="flip-card">
                            <Grid item sx={12} lg={12} justifyContent="center" lg={12} borderRadius={5} className="flip-card-inner">
                                <Paper elevation={24} sx={{ background: 'rgba(98, 0, 255,0.3)', borderRadius: 5 }} className="flip-card-front">
                                    <Typography variant="h1" sx={{ color: 'rgba(98, 0, 255' }} marginTop={12}>{data.title}</Typography>
                                </Paper>
                                <Paper elevation={24} sx={{ background: 'transparent', borderRadius: 5 }} item className="flip-card-back">
                                    <Typography variant="body1" sx={{ color: 'rgba(98, 0, 255' }} margin={10}>{data.description}</Typography>
                                </Paper>
                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>
        </ThemeProvider>
    );
}

export default FlipCard;