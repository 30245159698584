import React from 'react';
import { Button, Divider, Grid, Paper, styled, Typography, Link, TextField } from '@mui/material';
import { ThemeProvider } from "@emotion/react";
import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const bannertheme = createTheme({
    typography: {
        fontFamily: '"Helvetica Neue"',
        h1: {
            fontSize: "3rem",
            fontWeight: "bold",
            color: 'rgba(176, 0, 106)',
            fontFamily: '"Raleway"',
        },
        body1: {
            fontSize: "1.25rem",
            color: 'rgba(255, 255, 255,0.8)',
            fontFamily: '"Roboto"',
            fontWeight: 250,
        },
        body2: {
            fontSize: "1.5rem",
            color: "black",
            fontFamily: '"Helvetica Neue"',
            fontWeight: 150,
        }
    },
    textField: {
        styleOverrides: {
            text: {
                color: 'white',
                fontSize: '1rem',
                background: 'black',
                maxWidth: '2em',
                maxHeight: '2rem',
                minWidth: '2rem',
                minHeight: '2rem',
            },
        },
    },
    components: {
        // Name of the component
        MuiButton: {
            styleOverrides: {
                text: {
                    color: 'white',
                    fontSize: '1rem',
                    background: 'black',
                    maxWidth: '4em',
                    maxHeight: '4rem',
                    minWidth: '4rem',
                    minHeight: '4rem',
                },
            },
        },
    }
});

const Homebanner = () => {
    return (
        <ThemeProvider theme={bannertheme}>
            <Grid item justifyContent="left" marginRight={5}>
                <Typography variant="h1" marginBottom={5}>WELCOME TO KOC !</Typography>
                <Typography variant="body1" marginTop={5} marginBottom={2} lineHeight={1.3}>Kingdoms of Cryptopia(KOC) is an NFT collectible project, community, collaborative fantasy world & experimental game on the Ethereum blockchain. </Typography>
                <Typography variant="body1" marginTop={2} marginBottom={2} lineHeight={1.3}>Holding KOCs NFT makes you a citizen of Cryptopia giving you special privileges to Build / Collaborate / Special Abilities / Vote / Battle (Experimental) and Citizen only access to Paradise City, the captial of Cryptopia.</Typography>
                <Typography variant="body1" marginBottom={3} lineHeight={1.3}>There are 5 Kingdoms in Cryptopia each with 10K NFTs, The Rainbow Kingom will mint will be first. <Link variant="body2" color='rgba(245, 99, 66,0.8)' underline="none" underline="hover" href="http://localhost:3000/Origins">Click to Learn more.</Link></Typography>
            </Grid>
        </ThemeProvider >
    )
};

export default Homebanner;
