import React from 'react';
import { Button, Divider, Grid, Paper, styled, Typography, Stack } from '@mui/material';
import { Navbar, Mintbox, Homebanner, Gotobanner, Footer } from '../../components';
import { ThemeProvider } from "@emotion/react";
import { createTheme } from '@mui/material/styles';
import Kingdomcards from './Kingdomcards';

const theme = createTheme({
    typography: {
        h1: {
            fontSize: "3rem",
            fontWeight: "bold",
            color: 'rgba(176, 0, 106)',
            fontFamily: '"Big Shoulders Stencil Display"',
        },
        body1: {
            fontSize: "1.25rem",
            color: 'rgba(255, 255, 255,0.8)',
            fontFamily: '"Roboto"',
            fontWeight: 250,
        },
    }
});

const Kingdomtypes = () => {
    return (
        <Grid container p={2} >
            <Grid container md={12} lg={12} p={2} marginLeft={5} marginRight={5} justifyContent="right">
                <Navbar />
            </Grid >
            <ThemeProvider theme={theme}>
                <Grid container>
                    <Grid item md={12} lg={3} p={2}>
                        <Typography variant="h1"  marginTop={8}>The Kingdoms & Free City </Typography>
                        <Typography variant="body1" marginTop={10}>These are evolving stories that we wish to grow with member votes and ideas. We know where this story is going but the kingdoms and their back stories will continue to evolve.</Typography>
                    </Grid>
                    <Grid item md={12} lg={9} p={2}>
                        <Kingdomcards />
                    </Grid>
                </Grid>
            </ThemeProvider>
            <Grid container md={12} lg={12} p={2} marginLeft={5} marginRight={5}>
                <Footer />
            </Grid>
        </Grid>
    );
}

export default Kingdomtypes;
