import React from 'react';
import { Grid, Typography } from '@mui/material';
import { ThemeProvider } from "@emotion/react";
import { createTheme } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Faqdata } from './Faqdata';

const theme = createTheme({
    typography: {
        h1: {
            fontSize: "3rem",
            fontWeight: "bold",
            color: 'rgba(176, 0, 106)',
            fontFamily: '"Raleway"',
        },
        subtitle2: {
            fontSize: "1.5rem",
            fontWeight: 200,
            color: "white",
            alignItems: "center",
            fontFamily: '"Roboto"'
        }
    },
});

const Faq = () => {
    return (
        <Grid container p={2}>
            <ThemeProvider theme={theme}>
                <Grid container md={12} xs={12} sm={12} md={12} lg={12} p={2} marginLeft={1}>
                    <Grid item md={12} xs={12} sm={12} md={12} lg={12} sx={{ backgroundColor: "transparent" }}>
                        <Typography variant="h1" justifyContent="left">FAQ</Typography>
                        <br />
                        {Faqdata.map((data, index) => {
                            return (
                                <Accordion sx={{ backgroundColor: "transparent", boxShadow: "none" }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        sx={{ backgroundColor: 'rgba(176, 0, 106,.2)', boxShadow: "none" }}
                                    >
                                        <Typography variant="subtitle2">{data.question}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography variant="subtitle2">
                                            {data.answer}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            )
                        }
                        )}
                    </Grid>
                </Grid>
            </ThemeProvider>
        </Grid>
    );
}

export default Faq;
