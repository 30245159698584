import React from 'react';
import { Button, Divider, Grid, Paper, styled, Typography, Stack } from '@mui/material';
import { Navbar, Mintbox, Homebanner, Gotobanner, Footer } from '../../components';
import kocmap from '../../assets/maps/koc-maps.png'
import { ThemeProvider } from "@emotion/react";
import { createTheme } from '@mui/material/styles';
import FlipCard from './Flipcard';

const theme = createTheme({
    typography: {
        h1: {
            fontSize: "3rem",
            fontWeight: "bold",
            color: 'rgba(176, 0, 106)',
            fontFamily: '"Big Shoulders Stencil Display"',
        },
    }
});


const Cryptopians = () => {
    return (
        <Grid container p={2} >
            <Grid container md={12} lg={12} p={2} marginLeft={5} marginRight={5} justifyContent="right">
                <Navbar />
            </Grid >
            <ThemeProvider theme={theme}>
                <Grid container md={12} lg={12} p={2} margin={5} marginBottom={3}>
                    <Typography variant="h1" >Types of Cryptopians</Typography>
                </Grid>
                <Grid container md={12} lg={12} p={2} spacing={2} marginLeft={5} marginRight={5}>
                    <FlipCard />
                </Grid>
            </ThemeProvider >
            <Grid container md={12} lg={12} p={2} marginLeft={5} marginRight={5}>
                <Footer />
            </Grid>
        </Grid >
    );
}

export default Cryptopians;
