import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Grid from '@mui/material/Grid';
import nft1 from '../../assets/nfts/1.png'
import nft2 from '../../assets/nfts/2.png'
import nft3 from '../../assets/nfts/3.png'
import nft4 from '../../assets/nfts/4.png'
import nft5 from '../../assets/nfts/5.png'
import Stack from '@mui/material/Stack'

const nfts = [nft1, nft2, nft3, nft4, nft5]

const Nftcards = () => {
    return (
        <Grid container>
            {nfts.map((nft) => (
            <Grid item xs={12} sm={6} md={4} padding={1}>
                <Card sx={{ maxWidth: 345 }}>
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            image={nft}
                            alt="green iguana"
                        />
                    </CardActionArea>
                </Card >
            </Grid>
            ))}
        </Grid>
    )
};

export default Nftcards;
