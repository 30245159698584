import React, { useState, useEffect } from 'react';
import { Button, Divider, Grid, Paper, styled, Typography, Stack, Alert } from '@mui/material';
import { ThemeProvider } from "@emotion/react";
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import Web3 from "web3";
import { ethers } from 'ethers';
import abi from '../../config/abi';
import config from '../../config/config';


const minttheme = createTheme({
    typography: {
        fontFamily: '"Helvetica Neue"',
        h1: {
            fontSize: "5rem",
            fontWeight: "Bold",
            color: "black",
            alignItems: "center"
        },
        h2: {
            fontSize: "3rem",
            fontWeight: "Bold",
            color: "black",
            alignItems: "center"
        },
        h5: {
            fontSize: "1.5rem",
            fontWeight: "Bold",
            color: "black",
            alignItems: "center"
        },
        body1: {
            fontSize: "1.5rem",
            fontWeight: "Bold",
            color: "black",
            alignItems: "center"
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                text: {
                    color: 'black',
                    fontSize: '1rem',
                    background: 'rgba(214, 81, 119, 0.2)',
                    maxWidth: '4rem',
                    maxHeight: '2rem',
                    minWidth: '4rem',
                    minHeight: '2rem',
                    align: 'center'
                },
            },
        },
    }
});

const Mintboxlive = () => {
    // let cost = CONFIG.WEI_COST;
    // let gasLimit = CONFIG.GAS_LIMIT;
    // let totalCostWei = String(cost * mintAmount);
    // let totalGasLimit = String(gasLimit * mintAmount);
    let provider = window.ethereum;
    const web3 = new Web3(provider);
    const [defaultAccount, setDefaultAccount] = useState(null);
    const [mintedAmount, setmintedAmount] = useState(null);
    const [errorMessafe, setErrorMessage] = useState(null);
    const cost = config.DISPLAY_COST;
    const contract = new web3.eth.Contract(abi, config["CONTRACT_ADDRESS"]);
    // const account = web3.eth.getAccounts()

    const accountChangedHandler = (newAccount) => {
        setDefaultAccount(newAccount);
    }

    const connectWalletHandler = () => {
        if (provider) {
            window.ethereum.request({ method: 'eth_requestAccounts' })
                .then(result => {
                    accountChangedHandler(result[0]);
                })
        } else {
            setErrorMessage('Install Metamask Wallet');
        }
    }

    const [defaultMintCount, setdefaultMintCount] = useState(1);

    const incrementer = () => {
        let mintAmount = defaultMintCount + 1;
        if (mintAmount > 5) {
            mintAmount = 5
        }
        setdefaultMintCount(mintAmount)
    };


    const decrementer = () => {
        let mintAmount = defaultMintCount - 1;
        if (mintAmount < 1) {
            mintAmount = 1
        }
        setdefaultMintCount(mintAmount)
    };

    const mintToken = () => {
        contract.methods.totalSupply().call().then(result => { setmintedAmount(result) });

        contract.methods
            .mint(defaultMintCount)
            .send({
                gasLimit: String(3000000),
                to: config.CONTRACT_ADDRESS,
                from: defaultAccount,
                value: defaultMintCount * config.WEI_COST
            })
    }



    return (
        <ThemeProvider theme={minttheme}>
            <Paper elevation={24} margin={5} align="center" style={{ borderRadius: "10px", background: 'rgba(78, 189, 212, 0.2)', height: "100%" }}>
                <Typography marginTop={5} variant="h2">Whitelist Mint</Typography>
                <Typography marginLeft={10} marginRight={10} marginBottom={2} variant="subtitle1">Whitelist Mint is now open. Only for whitelisted Addresses.</Typography>
                <Typography marginTop={2} variant="h5">TOTAL MINTED</Typography>
                <Typography variant="h6">{mintedAmount}/{config.MAX_SUPPLY}</Typography>
                <Typography marginTop={5} variant="h5">TOTAL COST</Typography>
                <Typography variant="h6">{defaultMintCount * cost} Eth</Typography>
                <Grid item marginTop={5}>
                    <Typography variant="h1">{defaultMintCount}</Typography>
                </Grid>
                <Grid container spacing={2} justifyContent="center" marginBottom={2} alignItems="center">
                    <Grid item>
                        <Button
                            onClick={decrementer}

                            style={{
                                borderRadius: 10,
                                backgroundColor: "white",
                                color: "black",
                                padding: "9px 18px",
                                fontSize: "18px",
                            }}
                            variant="contained"
                        >-</Button>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1"></Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            onClick={incrementer}

                            style={{
                                borderRadius: 10,
                                backgroundColor: "white",
                                color: "black",
                                padding: "9px 18px",
                                fontSize: "18px"
                            }}
                            variant="contained"
                        >+</Button>
                    </Grid>
                </Grid>
                <Grid container justifyContent="center" marginBottom={5} alignItems="center">
                { defaultAccount ? 
                <Button onClick={mintToken}
                        style={{
                            borderRadius: 35,
                            backgroundColor: "#fc9d03",
                            padding: "18px 36px",
                            fontSize: "18px"
                        }}
                        variant="contained"
                    >Mint</Button> : 
                    <Button onClick={connectWalletHandler}
                        style={{
                            borderRadius: 35,
                            backgroundColor: "#e3306e",
                            padding: "18px 36px",
                            fontSize: "18px"
                        }}
                        variant="contained"> {provider ? "Connect" : <a href="https://metamask.io/download/"> Install Metamask</a>}</Button>
                    }
                </Grid>
            </Paper>
        </ThemeProvider >
    );
}

export default Mintboxlive;