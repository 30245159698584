import React from 'react';
import { Button, Divider, Grid, Paper, styled, Typography, Stack } from '@mui/material';
import { Navbar, Mintbox, Homebanner, Gotobanner, Footer } from '../../components';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import nft1 from '../../assets/nfts/1.png'
import nft2 from '../../assets/nfts/2.png'
import nft3 from '../../assets/nfts/3.png'
import map from '../../assets/maps/KOC-map.png'
import { ThemeProvider } from "@emotion/react";
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import kocmap from '../../assets/maps/koc-maps.png'

const theme = createTheme({
    typography: {
        fontFamily: '"Helvetica Neue"',
        h1: {
            fontSize: "4rem",
            fontWeight: 800,
            color: "black",
            alignItems: "center"
        },
        h2: {
            fontSize: "3rem",
            fontWeight: 900,
            color: "black",
            alignItems: "center"
        },
        h3: {
            fontSize: "2rem",
            fontWeight: 600,
            color: "black",
            alignItems: "center"
        },
        body1: {
            fontSize: "2rem",
            fontWeight: 300,
            color: 'rgba(71, 68, 112)',
            alignItems: "center"
        },
        subtitle1: {
            fontSize: "1rem",
            fontWeight: 500,
            color: 'rgba(71, 68, 112)',
            alignItems: "center"
        },
        subtitle2: {
            fontSize: "1.5rem",
            fontWeight: 200,
            color: "black",
            alignItems: "center"
        }
    },
});

const nfts = [[nft1, "DaLogikPapa", "Design.Code.Blockchain."], [nft2, "ShuQueen", "PM.Testing."], [nft3, "Joyverse", "Metaverse Designer."]]

const Team = () => {
    return (
        <Grid container p={2}>
            <ThemeProvider theme={theme}>
                <Grid container md={12} lg={12} p={2} marginLeft={5} marginRight={5} justifyContent="right">
                    <Navbar />
                </Grid>

                <Grid container md={12} lg={12} p={2} marginLeft={5} marginRight={7}>
                    <Grid item lg={8}>
                        <Typography variant="h2">What is this all about ?</Typography>
                        <Typography variant="subtitle2" marginBottom={2}>Kingdoms of Cryptopia is an NFT collectible & experimental game on the Ethereum blockchain. The Tokens are created using ERC 721 for those who love decentralized awesomness, games, fantasy stories and NFT collections.</Typography>
                        <Typography variant="subtitle2" marginBottom={2}>All Cryptopian Citizens (NFT's) are programmatically generated. Each is unique and unlike others. For the purposes of the experimental and to test capabilities of a decentralized decision making in the kingdom we hope kingdoms of cryptopia evolves as the community wants best. All Members through the NFTs will have  exclusive access to the captial city called Free City.</Typography>
                        <Typography variant="subtitle2" marginBottom={4}>There are 5 Kingdoms each with 10K NFTs, The Rainbow Kingdom will be open for Minting on March 1st, 2022.<a href="/Origins" sx={{ weight: 1, backgroundColor: "pink" }}> Learners guide starts with Origins.</a></Typography>
                        <Divider />
                    </Grid>
                    <Grid item lg={4} marginTop={8}>
                        <Paper elevation={24} sx={{ background: 'transparent'}}>
                            <img src={kocmap} alt="map" width="100%" height="40%" />
                        </Paper>
                    </Grid>
                </Grid>

                <Grid container spacing={1} lg={12} p={2} marginLeft={5} marginRight={5}>
                    <Grid item lg={4}>
                        <Typography variant="h2">Team</Typography>
                        <Typography variant="h4" marginTop={13}>A creative, technical bunch inspired by toddlers, fantasies, comics & dreams.</Typography>
                        <br />
                    </Grid>
                    <Grid item lg={8} marginTop={5}>
                        <Grid container>
                            {nfts.map((nft) => (
                                <Grid item xs={12} sm={6} md={4} padding={1}>
                                    <Card sx={{ maxWidth: 400 }}>
                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                                image={nft[0]}
                                            />
                                        </CardActionArea>
                                    </Card >
                                    <br />
                                    <Typography variant="h3" align="center">{nft[1]}</Typography>
                                    <Typography variant="subtitle1" align="center">{nft[2]}</Typography>
                                </Grid>
                            ))}
                        </Grid>
                        <br />
                        <br />
                        <Divider />
                    </Grid>
                </Grid>

                <Grid container md={12} lg={12} p={2} marginLeft={5} marginRight={7}>
                    <Grid item lg={12} sx={{ backgroundColor: "transparent" }}>
                        <Typography variant="h2">FAQ</Typography>
                        <br />
                        <Accordion sx={{ backgroundColor: "transparent", boxShadow: "none" }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography variant="subtitle2">What Insipired us ?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="subtitle2">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                    malesuada lacus ex, sit amet blandit leo lobortis eget.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion sx={{ backgroundColor: "transparent", boxShadow: "none" }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography variant="subtitle2">Why we are excited about Web3 ?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="subtitle2">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                    malesuada lacus ex, sit amet blandit leo lobortis eget.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion sx={{ backgroundColor: "transparent", boxShadow: "none" }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel3a-content"
                                id="panel3a-header"
                            >
                                <Typography variant="subtitle2">Community</Typography>
                            </AccordionSummary>
                        </Accordion>
                    </Grid>
                </Grid>
                <Grid container md={12} lg={12} p={2} marginLeft={5} marginRight={5}>
                    <Footer />
                </Grid>
            </ThemeProvider>
        </Grid>
    );
}

export default Team;
