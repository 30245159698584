import React, { useState, useEffect } from 'react';
import { Button, Divider, Grid, Paper, styled, Typography, Stack } from '@mui/material';
import { ThemeProvider } from "@emotion/react";
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import Web3 from 'web3';
import { ethers } from 'ethers';
import Clock from '../clock/Clock';

const minttheme = createTheme({
    typography: {
        fontFamily: '"Helvetica Neue"',
        h1: {
            fontSize: "5rem",
            fontWeight: "Bold",
            color: "black",
            alignItems: "center"
        },
        h2: {
            fontSize: "3rem",
            fontWeight: "Bold",
            color: "black",
            alignItems: "center"
        },
        body1: {
            fontSize: "1.5rem",
            fontWeight: "Bold",
            color: "black",
            alignItems: "center"
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                text: {
                    color: 'black',
                    fontSize: '1rem',
                    background: 'rgba(214, 81, 119, 0.2)',
                    maxWidth: '4rem',
                    maxHeight: '2rem',
                    minWidth: '4rem',
                    minHeight: '2rem',
                    align: 'center'
                },
            },
        },
    }
});

const Mintboxpresale = () => {
    const [errorMessage, setErrorMessage] = useState(null);
    const [defaultAccount, setDefaultAccount] = useState(null);
    const [userBalance, setUserBalance] = useState(null);
    const [connButtonText, setConnButtonText] = useState('Connect Wallet');

    // timer variables

    const [timerDays, setTimerDays] = useState();
    const [timerHours, setTimerHours] = useState();
    const [timerMinutes, settimerMinutes] = useState();
    const [timerSeconds, settimerSeconds] = useState();

    let interval;

    const startTimer = () => {
        const countDownDate = new Date("March 7,2022").getTime();

        interval = setInterval(() => {
            const now = new Date().getTime();
            const distance = countDownDate - now;
            const days = Math.floor(distance / (24 * 60 * 60 * 1000));
            const hours = Math.floor((distance % (24 * 60 * 60 * 1000)) / (1000 * 60 * 60));
            const minutes = Math.floor((distance % (60 * 60 * 1000)) / (1000 * 60));
            const seconds = Math.floor((distance % (60 * 1000)) / (1000));

            if (distance < 0) {
                // stop timer
                clearInterval(interval.current);
            } else {
                setTimerDays(days);
                setTimerHours(hours);
                settimerMinutes(minutes);
                settimerSeconds(seconds);
            }
        });

        console.log(timerDays);
    };

    useEffect(() => {
        startTimer();
    })

    let provider = window.ethereum;
    const web3 = new Web3(provider);
    const contract_address = "0x3e461A2c0e814B1b49930881e49cEd1aD15Cf64a";  // smart contract address
    // const contract = new web3.eth.Contract(abijson,contract_address);

    const connectWalletHandler = () => {
        if (provider) {
            window.ethereum.request({ method: 'eth_requestAccounts' })
                .then(result => {
                    accountChangedHandler(result[0]);
                })
        } else {
            setErrorMessage('Install Metamask Wallet');
        }
    }

    const accountChangedHandler = (newAccount) => {
        setDefaultAccount(newAccount);
    }

    const getUserBalance = (address) => {
        window.ethereum.request({ method: 'eth_getBalance', params: [address, 'latest'] })
            .then(balance => {
                setUserBalance(ethers.utils.formatEther(balance))
            })
    }

    const chainChangdHandler = () => {
        window.location.reload();
    }

    // window.ethereum.on('accountsChanged', accountChangedHandler);

    // window.ethereum.on('chainChanged', chainChangdHandler)

    /* minting function
    const mintToken = () => {
        //contract.methods.safeMint(defaultAccount).send({from: defaultAccount, value: "1000000000000000"})
        const totalSupply = contract.methods.mintRate().call()
        console.log(totalSupply)
    } */

    return (
        <ThemeProvider theme={minttheme}>
            <Paper elevation={24} margin={5} align="center" style={{ borderRadius: "10px", background: 'rgba(78, 189, 212, 0.2)', height: "100%" }}>
                <Typography marginTop={5} variant="h2">Mint Launch</Typography>
                <Typography marginLeft={10} marginRight={10} marginBottom={10} variant="subtitle1">PreSale will be open soon. Get Ready to Mint.</Typography>
                <Grid item marginTop={5} marginBottom={15}>
                    <Clock timerDays={timerDays} timerHours={timerHours} timerMinutes={timerMinutes} timerSeconds={timerSeconds} />
                </Grid>
                <Grid item marginTop={5} marginBottom={5}>
                    <Button onClick={connectWalletHandler} style={{
                        borderRadius: 35,
                        backgroundColor: "#e3306e",
                        padding: "18px 36px",
                        fontSize: "18px"
                    }}
                        variant="contained"
                    >
                        {provider ? "Connect Wallet" : (<a href="https://metamask.io/download/" target="_blank" rel="noopener noreferrer">Install Metamask</a>)}</Button>

                </Grid>
            </Paper>
        </ThemeProvider >
    );
}

export default Mintboxpresale;