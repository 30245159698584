import React from 'react';
import { Button, Divider, Grid, Paper, styled, Typography, TextField } from '@mui/material';
import { ThemeProvider } from "@emotion/react";
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import citizen from '../../assets/Citizens.gif';
import { Opacity } from '@mui/icons-material';

const theme = createTheme({
    typography: {
        h1: {
            fontSize: "3rem",
            fontWeight: "bold",
            color: 'rgba(176, 0, 106)',
            fontFamily: '"Raleway"'
        },
        h2: {
            fontSize: "1rem",
            fontWeight: "bold",
            color: 'rgba(245, 99, 66,0.8)',
            fontFamily: '"val stencil"'
        },
        body1: {
            fontSize: "1.2rem",
            fontWeight: 150,
            color: 'rgba(255, 255, 255, 0.9)',
            fontFamily: '"Roboto"',
        }
    }
});

const Members = () => {
    return (
        <ThemeProvider theme={theme}>
            <Grid container xs={12} sm={12} md={12} lg={12} marginBottom={5}>
                <Grid item xs={12} sm={6} md={6} lg={6} marginTop={5}>
                    <Typography variant="h1">Citizen Privileges</Typography>
                    <Typography variant="body1">Become a KOC Citizen.</Typography>
                    <Typography variant="body1">Voting priveleges on Next Kingdom Minting.</Typography>
                    <Typography variant="body1">Community Voting on evolution of the Kingdoms.</Typography>
                    <Typography variant="body1">Special access to Paradise City.</Typography>
                    <Typography variant="body1">Battles [Experimental].</Typography>
                    <Typography variant="body1">Community.</Typography>
                    <Typography variant="body1">Merchandize Discounts.</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} marginTop={5} align="right" justifyContent="space-around">
                    <Typography variant="h1">KOC Specs</Typography>
                    <Typography variant="body1">5 Kingdoms & Paradise City</Typography> 
                    <Typography variant="body1">10K Unique NFTs per Kingdom.</Typography>
                    <Typography variant="body1">Citizens, Traders, Knights, Nobles, UnoGene Nobles.</Typography>
                    <Typography variant="body1">Every NFT created from combination of 14 genome codes.</Typography>
                    <Typography variant="body1">Every NFT has unique skills & ability.</Typography>
                    <Typography variant="body1">Created from over 500+ traits and quirks.</Typography>
                    <Typography variant="body1">Special skills & strikepoints enable battles [Experimental]</Typography>
                </Grid>
            </Grid>
        </ThemeProvider >
    )
};

export default Members;
