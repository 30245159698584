import React from 'react';
import { Button, Divider, Grid, Paper, styled, Typography, Stack } from '@mui/material';
import { Navbar, Mintbox, Homebanner, Gotobanner, Footer } from '../../components';
import kocmap from '../../assets/maps/koc-maps.png'
import { ThemeProvider } from "@emotion/react";
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily: '"Copperplate"',
        h2: {
            fontSize: "3rem",
            fontWeight: "Bold",
            color: "black",
            alignItems: "center"
        },
        body1: {
            fontFamily: '"Helvetica Neue"',
            fontSize: "1.5rem",
            fontWeight: 300,
            color: "black",
            alignItems: "center"
        }
    }
});

const Dualbattles = () => {
    return (
        <Grid container p={2} >
            <Grid container md={12} lg={12} p={2} marginLeft={5} marginRight={5} justifyContent="right">
                <Navbar />
            </Grid >
            <ThemeProvider theme={theme}>
                <Grid container md={12} lg={12} p={2} margin={5} marginBottom={-3}>
                    <Typography variant="h2" >Origins</Typography>
                </Grid>
                <Grid container md={12} lg={12} p={2} marginLeft={5} marginRight={5}>
                    <Grid item lg={7}>
                        <Typography variant="body1" marginRight={2}>
                            Many many recent years ago, the Nation of Cryptopia was created by The Uno, a being who believed in decentralized technology, having fun, peace and harmony. 
                            He created 5 kingdoms on this land. These kingdoms are the Rainbow Kindgom, Paw Kingdom, Sugar Kingdom, Candy Kingdom, Waterfall Kingdom and the captial called "Royal City" where exclusive members will have priveleges such as voting rights on the future of the nation itself. 
                            He deemed these kingdoms to co-exist in peace through the power of decentralized blockchain technology. 
                        </Typography>
                        <Typography variant="body1" marginTop={5} marginRight={2}>
                            Each Kingdom begins with the first generation of 10,000 unique and programmatically generated Nft Citizens. Each kingdom has Royals, Nobility, Knights, Merchants & Citizens. Everyone in each Kingdom can vote for special purposes within their own kingdoms, while only the Nobles and Royalty from each kingdom can vote for special purposes of the Nation of Cryptopia. 
                            While the Kingdom is built to have peace, healthy competiton in forms of duals and battles is allowed. However, duals and battles have consequences on the game. More on that here.  
                        </Typography>
                        <Typography variant="body1" marginTop={5} marginRight={2}>
                            Rainbow Kingdom Mint is open. Mint here to play and become a citizen of Cryptopia.
                        </Typography>
                    </Grid>
                    <Grid item lg={5}>
                        <Paper elevation={24} style={{ backgroundColor: 'rgba(71, 68, 112,0)', }}>
                            <img src={kocmap} alt="map" width="100%" height="50%" />
                        </Paper>

                    </Grid>
                </Grid>
            </ThemeProvider>
            <Grid container md={12} lg={12} p={2} marginLeft={5} marginRight={5}>
                <Footer />
            </Grid>
        </Grid>
    );
}

export default Dualbattles;
