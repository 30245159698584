import React from 'react';

export const Originsdata = [
    {
		title: "The Origins",
		description: "Many many years ago, the Nation of Cryptopia was created by The Unos, at the moment their beginnings are a mystery. What is known is that they live somewhere on the Ethereum blockchain. They believed in decentralized technology, having fun, peace and harmony.Thus, they created 5 kingdoms and exclusive members only nuetral city called 'Paradise city'. The kingdoms were called the Rainbow Kindgom, Paw Kingdom, Sugar Kingdom, Candy Kingdom & Waterfall Kingdom. Each Kingdom is home to 10,000 Cryptopians (NFTs) today. It will remain the same untill 'The night of red moon'.Everyone in each kingdom exist as a ERC-721 tokens on the Ethereum blockchain and hosted on IPFS.",
	},
    {
		title: "Genetics & Powers",
		description: "Each Cryptopian (NFTs) in each kingdom is unique and programmatically generated. Each cryptopian is a unique noble or knight or trader or citizen, but some are rarer than others. \
        Each Cryptopian has a unique genetic code of built from 10 gene code. Some genomic codes are rarer than others and may have more value. \
        Each NFT with a unique genetic comes with its own special secret gene mix that gives each cryptopian special skills and powers. \
        \
        This will come in handy when we open Duals and Battles [Experimental].",
	},
    {
		title: "The Game",
		description: "Kingdoms of cryptopia is an experimental community game on the blockchain. The creators of KoC(Kingdoms of cryptopia) have longed loved story telling, board games and the philosophies of human motivations.",
	},
    {
		title: "NFTs",
		description: "Only 10,000 Cryptopians per Kingdom will be open for minting. Yes, this is all begins with the Rainbow kingdom, however, which kingdom will be open for minting next is a decision that will be taken by the owners of the NFTs. Also, Each NFT/Cryptopian is unqiue however is based on 4 types - Nobles, Knights, Traders and Citizen. There is also a unique gene type of UnoGene nobles that are rare."
	},
    {
		title: "NFT Gene, Skills & Strikepoints",
		description: "Each NFT is created using 14 Gene Code across 500+ traits. Each NFT has specific skill and strick points. As we build the game out, this will become more significant"
	},
    {
		title: "Strike Points and Game skills",
		description: "As we evolve the game, we will enable additional minting of Skills and strike points which will enable members to battle and daul as a part of the game. However, these will be tied to the original NFT and will have a cooling period. These will be free to mint."
	},
    {
		title: "Member Voting",
		description: "While, we are going to begin this game by minting of Rainbow Kingdom, all other decisions around the roadmap will be decided by member votes.\
        Example, the launch date of next Kingdom or expanding the story of each kingdom or to building new features and capabilities to Paradise City. All of decisons will be taken through member voting, though we the creators will ensure the options to vote on are within the reasonable limits and possibilties of the team."
	},
    {
		title: "Well of Giving",
		description: "Only through member voting, will the 'Well of Giving' be open so new players can mint new NFT's for a limited amount of time. Well of giving will bring to life only 499 New Cryptopians in a specific kingdom and only members of that kingdom can vote for it."
	},
    {
		title: "Night of the Red Moon",
		description: "Night of the Red Moon will be a vote based event in the game, that will allow members to evolve their Cryptopians and Vote to open `Well of Giving` that will open minting of new Crypotians for a limited amount of time only. This will allow evolution of the game and other players to get involved in the game and community."
	},
    {
		title: "Battles & Duals [Experimental]",
		description: "Currently, an experimental feature that we are exploring that would allow NFT holders to dual each other for superiority or battles for fun. However, Duals and Battles will have consquences as for Winners and losers."
	},

]
