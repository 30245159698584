import React from 'react';
import { Button, Divider, Grid, Paper, styled, Typography, Stack } from '@mui/material';
import { Navbar, Mintbox, Homebanner, Gotobanner, Footer } from '../../components';
import kocmap from '../../assets/maps/koc-maps.png'
import { ThemeProvider } from "@emotion/react";
import { createTheme } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Originsdata } from './Originsdata';

const theme = createTheme({
    typography: {
        h2: {
            fontSize: "1.5rem",
            fontWeight: 400,
            color: 'White',
            fontFamily: '"Roboto"',
        },
        body1: {
            fontFamily: '"Roboto"',
            fontSize: "1.2rem",
            color: "white",
            alignItems: "center"
        }
    }
});

const Origins = () => {
    return (
        <Grid container p={2} >
            <Grid container md={12} lg={12} p={2} marginLeft={5} marginRight={5} justifyContent="right">
                <Navbar />
            </Grid >
            <ThemeProvider theme={theme}>
                <Grid container md={12} lg={12} p={2} marginLeft={5} marginRight={5}>
                    <Grid item lg={12}>
                        <Grid item>
                            <Grid container md={12} spacing={1} lg={12} >
                                {Originsdata.map((data, index) => {
                                    return (
                                        <Accordion sx={{ backgroundColor: 'rgba(176, 0, 106,.8)', boxShadow: "none" }}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                                sx={{ backgroundColor: 'transparent', boxShadow: "none" }}
                                                default={true}
                                            >
                                                <Typography variant="h2">{data.title}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography variant="body1">
                                                    {data.description}
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    )
                                }
                                )}
                                <Accordion sx={{ backgroundColor: 'rgba(176, 0, 106,.8)', boxShadow: "none" }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    sx={{ backgroundColor: 'transparent', boxShadow: "none" }}
                                >
                                    <Typography variant="h2">Map of Kingdoms of Cryptopia</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Paper elevation={24} sx={{ background: 'transparent' }} align="center">
                                        <img src={kocmap} alt="map" width="100%" height="100%" />
                                    </Paper>
                                </AccordionDetails>
                            </Accordion>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </ThemeProvider>
            <Grid container md={12} lg={12} p={2} marginLeft={5} marginRight={5}>
                <Footer />
            </Grid>
        </Grid>
    );
}

export default Origins;
