import React from 'react';

export const Cdata = [
	{
		title: "Nobles",
		description: "Nobles are decendents of The Uno's.They have special genetics, powers and traits. Nobles are rare, however, their are rarer nobles with special powers because of their closer genetic connection to the Uno's."
	},
	{
		title: "Knights",
		description: "Knights are warriors who love to protect, gaurd the intergity of the Kingdoms. Knights love to train, sleep and eat. Not all Knights are the same."
	},
	{
		title: "Traders",
		description: "Traders are run all business in thee kingdom. They ensure everyone in the kingdom has what they need from sugar to spices to weapons to special Items. Traders ensure proper supply in the kingdoms. "
	},
	{
		title: "Citizens",
		description: "Citizens are the happiest bunch in the kingdom, they love to relax and enjoy living under the sun. They are most common type of NFTs available. However, Citizens can evolve into Knights or Nobles based on the potions they consume at the night of under the moon. "
	},
]