import React from 'react';
import { Button, Divider, Grid, Paper, styled, Typography, Stack } from '@mui/material';
import { Navbar, Mintbox, Homebanner, Gotobanner, Footer } from '../../components';
import { ThemeProvider } from "@emotion/react";
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        h1: {
            fontSize: "3rem",
            color: 'rgba(176, 0, 106)',
            fontWeight: "bold",
            fontFamily: '"Raleway"',
        },
        h2: {
            fontSize: "1.5rem",
            fontWeight: 450,
            color: 'rgba(176, 0, 106)',
            fontFamily: '"Raleway"',
        },
        body1: {
            fontSize: "1rem",
            fontWeight: 300,
            color: "white",
            alignItems: "center"
        }
    }
});


const whitelist = () => {
    return (
        <ThemeProvider theme={theme}>
            <Grid container>
                <Grid container sm={12} sx={12} md={12} lg={12} p={2}>
                    <Grid sm={12} sx={12} md={12} lg={12} marginBottom={5}>
                        <Typography variant="h1" marginTop={1}>Rainbow Kingdom Mint</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} lg={3} p={1}>
                        <Paper xs={6} sm={6} md={3} lg={3} elevation={24} margin={2} align="center" style={{ borderRadius: "10px", background: 'rgba(78, 189, 212, 0.2)', height: "100%" }}>
                            <br />
                            <Typography variant="h2" marginTop={1}>Whitelist Mint</Typography>
                            <Typography variant="body1" marginTop={2}>April ,2022</Typography>
                            <Typography variant="body1" margin={2}>0.07 ETH</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} lg={3} p={1}>
                        <Paper xs={6} sm={3} md={3} lg={3} elevation={24} margin={2} align="center" style={{ borderRadius: "10px", background: 'rgba(78, 189, 212, 0.2)', height: "100%" }}>
                            <br />
                            <Typography variant="h2" marginTop={1}>Presale Minting</Typography>
                            <Typography variant="body1" marginTop={2}>April ,2022</Typography>
                            <Typography variant="body1" margin={2}>0.14 ETH</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} lg={3} p={1}>
                        <Paper xs={6} sm={6} md={3} lg={3} elevation={24} margin={2} align="center" style={{ borderRadius: "10px", background: 'rgba(78, 189, 212, 0.2)', height: "100%" }}>
                            <br />
                            <Typography variant="h2" marginTop={1}>Public Mint</Typography>
                            <Typography variant="body1" marginTop={2}>April ,2022</Typography>
                            <Typography variant="body1" margin={2}>0.21 ETH</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} lg={3} p={1}>
                        <Paper xs={6} sm={6} md={3} lg={3} elevation={24} margin={2} align="center" style={{ borderRadius: "10px", background: 'rgba(78, 189, 212, 0.2)', height: "100%" }}>
                            <br />
                            <Typography variant="h2" marginTop={1}>Reveal</Typography>
                            <Typography variant="body1" marginTop={2}>April ,2022</Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>

        </ThemeProvider>
    )
}

export default whitelist