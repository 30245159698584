import React from 'react';

export const Kdata = [
    {
		title: "Rainbow Kingdom",
		description: "First Kingdom to open for Sale/Mint. Home to the most adorable cryptopians, who love to fly around the kingdom and spread cuteness. The Rainbow kingdom has amazing clouds,rainbows and trade ports to the Continent. Traders in the Rainbow kingdom are the richest."
	
	},
	{
		title: "Paw Kingdom",
		description: "Stretches from the north to the south and is the land of warriors, who love adventures."
	},
	{
		title: "Sugar Kingdom",
		description: "Closest and the sweetest kingdom, they have magic that is unique to this kingdom and the citizens are the sweetest. They live on sugar power. "
	},
	{
		title: "Candy Kingdom",
		description: "The smallest kingdom in Cryptopia, home to the unique citizens with special affinity to Candy."
	},
	{
		title: "Waterfall Kingdom",
		description: "The largest kingdom in Cryptopia, home to strongest, wild and mysterious citizens who believe in decentralized organizations."
	},
	{
		title: "Paradise City",
		description: "Exclusive members only city where amazing experiences and utilities would be available. We are exploring options to build a space in the metaverse."
	},
]