import React from 'react';
import { Button, Divider, Grid, Paper, styled, Typography, TextField } from '@mui/material';
import { ThemeProvider } from "@emotion/react";
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import citizen from '../../assets/Citizens.gif';
import { Opacity } from '@mui/icons-material';

const bannertheme = createTheme({
    typography: {
        h1: {
            fontSize: "4rem",
            fontWeight: "bold",
            color: 'rgba(255, 255, 255, 0.9)',
            fontFamily: '"Helevetica nue"',
        },
    }
});

const Homedescription = () => {
    return (
        <ThemeProvider theme={bannertheme}>
            <Grid item>
                <Typography variant="h1" marginTop={5}>Kingdoms of cryptopia is here !</Typography>
                <Typography variant="body1" marginBottom={2}>All Cryptopian Citizens (NFT's) are programmatically generated. Each is unique and unlike others. For the purposes of the experimental and to test capabilities of a decentralized decision making in the kingdom we hope kingdoms of cryptopia evolves as the community wants best. All Members through the NFTs will have  exclusive access to the captial city called Free City.</Typography>
                <Typography variant="body1" marginBottom={4}>There are 5 Kingdoms each with 10K NFTs, The Rainbow Kingdom will be open for Minting on March 1st, 2022.<a href="kingdomsofcryptopia/Origins" sx={{ weight: 1, backgroundColor: "pink" }}> Learners guide starts with Origins.</a></Typography>
            </Grid>
        </ThemeProvider >
    )
};

export default Homedescription;
