import * as React from 'react';
import { useState } from 'react';
import { styled, AppBar } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Grid, Typography }  from '@mui/material';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Input, ListItem, MenuItem } from '@mui/material';
import { Check, Filter } from '@mui/icons-material';
import { Filterdata } from './Filterdata'
import { Nftcards, Navbar } from '../../components';



const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const SubMenu = ({ item }) => {
    const [open, setOpen] = useState(false);
    return (
        <Box>
            <MenuItem color="error" onClick={() => setOpen(!open)}>
                <ListItem>{item.title}</ListItem>
            </MenuItem>
            {item.subnav && open ? item.subnav.map((item, index) => {
                return (
                    <Grid>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox />} label={item.title} />
                        </FormGroup>
                    </Grid>)
            }) : null}
            <Divider />
        </Box>
    )
}


export default function Gallery(props) {

    return (
        <Grid container p={2}>
            <Grid container md={12} lg={12} p={2} marginLeft={5} marginRight={5} marginBottom={5} justifyContent="right">
                <Navbar />
            </Grid >
            <Grid item xs={3}>
                <Stack spacing={1} p={1}>
                    <Typography variant="h2">Filter</Typography>
                    <Divider />
                    {Filterdata.map((item, index) => { return <SubMenu item={item} key={index} />; })}
                </Stack>
            </Grid>
            <Grid item xs={9}>
                <Nftcards />
            </Grid>
        </Grid>
    );
}
