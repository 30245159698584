import React from 'react';
import * as FaIcons from 'react-icons/fa';

export const Filterdata = [
	{
		title: "Base Type",
		icons: <FaIcons.FaAirbnb />,
		subnav: [
			{
				title: "Royalty",
			},
			{
				title: "Lobility",
			}
		]
	},
	{
		title: "Background Type",
		icons: <FaIcons.FaAddressCard />,
		subnav: [
			{
				title: "Blue",
			},
			{
				title: "Red",
			}
		]
	},
	{
		title: "Patterns Type",
		icons: <FaIcons.FaAngular />,
	}
];
