import React from 'react';
import { Button, Divider, Grid, Paper, styled, Typography, Stack, TextField } from '@mui/material';
import { ThemeProvider } from "@emotion/react";
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { FaDiscord, FaTwitter, FaInstagram } from 'react-icons/fa';

const gototheme = createTheme({
    typography: {
        fontFamily: '"Helvetica Neue"',
        h1: {
            fontSize: "3rem",
            fontWeight: "bold",
            color: "white"
        },
        body1: {
            fontSize: "1.5rem",
            color: "white",
            fontFamily: '"Helvetica Neue"',
            fontWeight: 150,
        }
    },
    components: {
        // Name of the component
        MuiButton: {
            styleOverrides: {
                text: {
                    color: 'white',
                    fontSize: '1rem',
                    background: 'rgba(0, 0, 0,0.8)',
                    maxWidth: '6rem',
                    maxHeight: '2rem',
                    minWidth: '4rem',
                    minHeight: '2rem',
                },
            },
        },
    }
});

const Gotobanner = () => {
    return (
        <Grid container marginTop="calc(3% + 2px)" margin={2} justifyContent="Center">
            <ThemeProvider theme={gototheme}>
            <Stack>
                <Stack spacing={1} direction="row">
                    <Button sx={{fontSize:"10px"}}>@DeepDrift2022</Button>
                    <Button href="https://discord.gg/awr9zvsj" target="_blank" rel="noopener noreferrer"><FaDiscord /></Button>
                    <Button href="https://twitter.com/KOCAC4" target="_blank" rel="noopener noreferrer"><FaTwitter /></Button>
                    <Button><FaInstagram /></Button>
                </Stack>
            </Stack>
            </ThemeProvider>
        </Grid>
    )
};

export default Gotobanner;
