import React from 'react'
import { Button, Divider, Paper, Grid, IconButton, SwipeableDrawer, Typography, Menu, MenuItem, Stack } from '@mui/material';
import { ThemeProvider } from "@emotion/react";
import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const clocktheme = createTheme({
    typography: {
        fontFamily: '"Helvetica Neue"',
        h1: {
            fontSize: "5rem",
            fontWeight: "Bold",
            color: "black",
            alignItems: "center"
        },
        h2: {
            fontFamily: '"Copperplate"',
            fontSize: "3rem",
            color: "#e3306e",
            alignItems: "center"
        },
        body1: {
            fontSize: "1.3rem",
            fontFamily: '"Copperplate"',
            color: "#e3306e",
            alignItems: "center"
        }
    }
});

const Clock = ({ timerDays, timerHours, timerMinutes, timerSeconds }) => {
    return (
        <ThemeProvider theme={clocktheme}>
            <Grid item justifyContent="center">
            <Paper elevation={24} margin={5} align="center" style={{ borderRadius: "10px", background: 'rgba(78, 189, 212, 0.2)', width:"70%", height: "100%" }}>
                <br/>
                <Typography variant="h2">{timerDays} {timerHours} {timerMinutes} {timerSeconds}</Typography>
                <Typography variant="body1">Days Hours Mins Secs</Typography>
                <br/>
            </Paper>
            </Grid>
        </ThemeProvider>
    )
}

export default Clock