import React from 'react';

export const Faqdata  = [
	{
		question: "What inspired us to build kingdoms of Cryptopia ?",
		answer: "The world we live in is faciniating, inspirations are available at every nook and corner. Believe it or not, the biggest inspiration for Kingdoms of Cryptopia was a toddler and the toddlers cool and funny stuffed toys. DaLogikpapa created a fantasy stories for this toddler full of fun, peace and creative characters which then inspired us to make this world available for everyone.",
	},
    {
		question: "How is Kingdoms of Cyrptopia different ?",
		answer: "Kingdoms of Cryptopia is aimed to be a community built fantasy world on the blockchain, having KOC nfts would allow members to participate and vote on creating the awesome story arc of this world. We believe in community and how a collective community can work together in shaping the world. In Many ways, KOC is a possible meta example of our society.",
	},
    {
		question: "What do owners of KOC NFT's get ?",
		answer: "Voting privelges on story arc, Kingdom Minting decisions, Private access to Paradise city - a members only space, Community Battles & Duals (Experimental), Ability to profit from Merchandize sales.",
	},
	{
		question: "How many kingdoms & NFTs exist in KOC?",
		answer: "There are 5 Kingdoms in KOC each will have 10K KOC, the Rainbow kingdom will be open for mint first",
	},
	{
		question: "Are there some NFT's that won't be available for minting?",
		answer: "200 NFTs are kept aside for the team / giveaways and contests that we hope to setup.",
	},
	{
		question: "Where is Paradise City?",
		answer: "We are currently exploring where to build paradise city, we are working with a metaverse game designed to explore possbilities of creating Paradise City on Sandbox or as a lounge. It's on the roadmap and we hope to get to this place on this journey together.",
	},
	{
		question: "Who is the team behind KOC?",
		answer: "The team behind KOC is an experienced technical and creative squad. We have been working in Technology for more than a decade, building cool S.H.I.T that we can't talk about here :).  Every element of KOC has been designed by our team and nothing is sourced. We are simply technology folks who love blockchain possibilities, watching anime and fantasy shows, playing boardgames and principly rooted in making a difference in the world.",
	},
];
